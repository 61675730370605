
import {Modal as BsModal} from 'bootstrap';
import {
  defineComponent, onMounted, ref, inject
} from 'vue';
import {ElSteps, ElStep} from 'element-plus';
import axios, {AxiosInstance, AxiosPromise} from 'axios';

import Modal from './Modal';

interface Choice {
  points: number,
  answer: string,
  text: string
}

interface Question {
  uid: number,
  question: string,
  choices: Array<Choice>
}

export default defineComponent({
  components: {
    ElSteps,
    ElStep,
  },
  props: {
    getItemsUrl: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const questions = ref<Array<Question>>([]);
    let selectedAnswers: Array<number> = [];
    let topicCategorySelected = false;
    const modal: Modal | undefined = inject('modal');
    const modalElement = ref<HTMLDivElement>();

    function fetchContent() {
      return axios({
        url: props.getItemsUrl,
        method: 'get',
      })
        .then((response) => {
          questions.value = response.data;
          selectedAnswers = new Array(questions.value.length).fill(0);
        })
        .catch((error) => {
          console.error('Oops an error occurred: ', error);
        });
    }

    function stepBack(): void {
      if (modal) {
        modal.activeStep.value -= 1;
        selectedAnswers[modal.activeStep.value] = 0;
      }
    }

    function chooseValue(points: number, target: string): void {
      if (window.innerWidth > 991 && target === 'choice') {
        return;
      }
      if (modal) {
        if (points === -1) {
          topicCategorySelected = true;
        } else {
          selectedAnswers[modal.activeStep.value] = points;
        }
        modal.activeStep.value += 1;
        modal.totalResult.value = selectedAnswers.reduce((counter, obj) => counter + obj, 0);
        if (modal.activeStep.value === questions.value.length && modal) {
          let mainCategory;
          switch (true) {
            case (modal.totalResult.value <= 10):
              mainCategory = 1;
              break;
            case (modal.totalResult.value >= 15 && modal.totalResult.value <= 30):
              mainCategory = 2;
              break;
            case (modal.totalResult.value >= 35 && modal.totalResult.value <= 50):
              mainCategory = 3;
              break;
            default:
              break;
          }
          modal.setPersonalization(mainCategory, topicCategorySelected ? 5 : undefined);
        }
      }
    }

    function linkToHomepage() {
      if (window.location.pathname === '/') {
        modal?.bsModal?.hide();
      } else {
        // @ts-ignore
        window.open(window.location.origin, '_self');
      }
    }

    onMounted(() => {
      if (modal) {
        modal.bsModal = new BsModal(modalElement.value as HTMLDivElement, {});
        modal.mainCategory.value = modal.getPersonalizationMainCategory();
        modal.topicCategory.value = modal.getPersonalizationTopicCategory();

        modalElement.value?.addEventListener('show.bs.modal', async () => {
          if (questions.value.length === 0) {
            await fetchContent();

            if (modal.getPersonalizationMainCategory()) {
              modal.activeStep.value = questions.value.length;
            }
          }
        });
      }
    });

    return {
      questions, modal, modalElement, chooseValue, stepBack, linkToHomepage
    };
  },
});
