import { Plugin } from '@vue/runtime-core';
import DsPhotoSwipe from "./PhotoSwipe";

const PhotoSwipePlugin: Plugin = {
  install(app, options) {
    let photoSwipe = new DsPhotoSwipe();
    app.provide('photoSwipe', photoSwipe);
  }
}

export default PhotoSwipePlugin;
