import { createApp } from 'vue'
//@ts-ignore
import PhotoSwipeComponent from './PhotoSwipe.vue';

export default class PhotoSwipe {
  private $vm: any;

  constructor(){
    const div = document.createElement('div');
    document.body.appendChild(div);

    this.$vm = createApp(PhotoSwipeComponent).mount(div);
  }

  open(event: Event, index: number) {
    this.$vm.open(event, index);
  }

  close() {
    this.$vm.close();
  }
}
