import Player from './Player';

class YoutubePlayer extends Player {
  media: HTMLIFrameElement | null = null;

  // @ts-ignore
  private player: Window.YT.Player | null = null;

  constructor(element: any) {
    super(element);
    this.element.addEventListener('videoPlayer.loadScript', () => {
      this.events.scriptLoaded = new CustomEvent('videoPlayer.youtubeScriptLoaded');
      this.media = this.element.querySelector('iframe');
      if (this.media) {
        this.importScript(() => {
          // @ts-ignore
          this.player = new window.YT.Player(this.media, {
            events: {
              onReady: () => {
                this.onPlayerReady();
              },
              onStateChange: () => {
                this.onPlayerStateChange();
              },
            },
          });
        });
      }
    });
  }

  importScript(onload: any) {
    if (!document.scripts.namedItem('script-youtube-player')) {
      // @ts-ignore
      window.onYouTubeIframeAPIReady = () => {
        // @ts-ignore
        document.scripts['script-youtube-player'].dataset.loaded = true;
        document.dispatchEvent(this.events.scriptLoaded);
        if (onload) {
          onload();
        }
      };
      super.importScript('https://www.youtube.com/iframe_api', 'script-youtube-player');
      // @ts-ignore
    } else if (!document.scripts['script-youtube-player'].dataset.loaded) {
      if (onload) {
        document.addEventListener('videoPlayer.youtubeScriptLoaded', () => {
          onload();
        });
      }
    }
  }

  play() {
    this.player.playVideo();
  }

  stop() {
    this.player.pauseVideo();
  }

  mute() {
    this.player.mute();
  }

  unmute() {
    this.player.unMute();
  }
}

export default YoutubePlayer;
