import {Ref, ref} from 'vue';
import {Modal as BsModal} from 'bootstrap';
import Cookies from 'js-cookie';

const DAYS_IN_MONTH = 31;
const KEY_COOKIES_PERSONALIZATION_TOPIC_CATEGORY = 'personalizationTopicCategory';
const KEY_COOKIES_PERSONALIZATION_MAIN_CATEGORY = 'personalizationMainCategory';
const KEY_COOKIES_PERSONALIZATION_INFO_PANEL_CLOSE = 'personalizationInfoPanelClose';

export default class Modal {
  bsModal: BsModal | null;

  mainCategory: Ref<number | undefined>;

  topicCategory: Ref<number | undefined>;

  infoPanelVisibility: Ref<boolean>;

  totalResult: Ref<number>;

  activeStep: Ref<number>;

  constructor() {
    this.topicCategory = ref(undefined);
    this.mainCategory = ref(undefined);
    this.bsModal = null;
    this.totalResult = ref<number>(0);
    this.activeStep = ref<number>(0);

    const infoPanelVisibilityCookie = Cookies.get(KEY_COOKIES_PERSONALIZATION_INFO_PANEL_CLOSE);
    this.infoPanelVisibility = ref(false);
    setTimeout(() => {
      this.infoPanelVisibility.value = infoPanelVisibilityCookie !== '1';
    }, 5000);
  }

  async openModal(): Promise<void> {
    if (this.mainCategory.value) {
      await this.resetPersonalization();
    }
    await this.bsModal?.show();
  }

  closeInfoPanel(): void {
    this.infoPanelVisibility.value = false;
    Cookies.set(KEY_COOKIES_PERSONALIZATION_INFO_PANEL_CLOSE, '1', {
      sameSite: 'strict',
      secure: true,
    });
  }

  setPersonalization(mainCategory: number | undefined, topicCategory: number | undefined = undefined): void {
    if (mainCategory) {
      this.mainCategory.value = mainCategory;
      this.infoPanelVisibility.value = false;
      Cookies.set(KEY_COOKIES_PERSONALIZATION_MAIN_CATEGORY, mainCategory.toString(), {
        expires: DAYS_IN_MONTH,
        sameSite: 'strict',
        secure: true,
      });
    }

    if (topicCategory) {
      this.topicCategory.value = topicCategory;
      Cookies.set(KEY_COOKIES_PERSONALIZATION_TOPIC_CATEGORY, topicCategory.toString(), {
        expires: DAYS_IN_MONTH,
        sameSite: 'strict',
        secure: true,
      });
    }
  }

  resetPersonalization(): void {
    this.mainCategory.value = undefined;
    this.topicCategory.value = undefined;
    this.infoPanelVisibility.value = true;
    this.activeStep.value = 0;
    this.totalResult.value = 0;
    Cookies.remove(KEY_COOKIES_PERSONALIZATION_TOPIC_CATEGORY);
    Cookies.remove(KEY_COOKIES_PERSONALIZATION_MAIN_CATEGORY);
    Cookies.remove(KEY_COOKIES_PERSONALIZATION_INFO_PANEL_CLOSE);
  }

  getPersonalizationMainCategory(): number | undefined {
    const mainCategory = Cookies.get(KEY_COOKIES_PERSONALIZATION_MAIN_CATEGORY);
    return Number(mainCategory);
  }

  getPersonalizationTopicCategory(): number | undefined {
    const topicCategory = Cookies.get(KEY_COOKIES_PERSONALIZATION_TOPIC_CATEGORY);
    return Number(topicCategory);
  }
}
