import {Plugin} from '@vue/runtime-core';
import Modal from './Modal';
import PersonalizationModal from './Modal.vue';

const ModalPlugin: Plugin = {
  install(app) {
    const modal = new Modal();
    app.provide('modal', modal);
    app.component('PersonalizationModal', PersonalizationModal);
  },
};

export default ModalPlugin;
