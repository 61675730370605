import '../Scss/style.scss';
import {defineAsyncComponent, inject, onMounted, ref} from 'vue';
import LazyLoad from 'vanilla-lazyload';
import {Component} from '@vue/runtime-core';
import createApp from '@digitalwerk/frontend-muster';
import YoutubePlayer from './directives/video-player/YoutubePlayer';
import HTMLPlayer from './directives/video-player/HTMLPlayer';
import {VueModal} from './plugins';
import Cookies from 'js-cookie';
import {DsPhotoSwipe} from './plugins';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const NewsList = defineAsyncComponent(() => import('@dw_page_types/Vue/News/List.vue'));
const StatsItem = defineAsyncComponent(() => import('./Components/StatsItem.vue'));
const Mapbox = defineAsyncComponent(() => import('./Components/Mapbox.vue'));
const QuizSlider = defineAsyncComponent(() => import('./Components/QuizSlider.vue'));
const Quiz = defineAsyncComponent(() => import('./Components/Quiz.vue'));

const projectRootComponent: Component = {
  components: {
    'news-list': NewsList,
    'quiz-slider': QuizSlider,
    StatsItem,
    Mapbox,
    Quiz,
  },
  inject: ['modal'],
  methods: {
    createdHook() {
      (document.querySelector(':root') as HTMLElement)?.style
        .setProperty('--vh', window.innerHeight / 100 + 'px');

      window.addEventListener('resize', () => {
        (document.querySelector(':root') as HTMLElement)?.style
          .setProperty('--vh', window.innerHeight / 100 + 'px');
      })
    },
  },
  /* ======== SETUP ======== */
  setup() {
    const header = ref<HTMLElement | null>(null);
    const lazyLoad = new LazyLoad({
      threshold: 0,
      elements_selector: '[lazy]',
      class_loading: 'lazy-loading',
      class_loaded: 'lazy-loaded',
      class_applied: 'lazy-bg-loaded',
      class_error: 'lazy-error',
    });

    const photoSwipe = inject('photoSwipe');

    onMounted(() => {
      document.querySelectorAll('.bg-media-directive').forEach((element) => {
        const el: HTMLElement = element as HTMLElement;
        const video: HTMLVideoElement | HTMLIFrameElement | null = el.querySelector('.media-item');
        const videoType = el?.dataset.player;
        const cookieValidation: string | undefined = Cookies.get('OptanonConsent');

        // if (true) {
        if (cookieValidation?.includes('C0004:1') || videoType === 'mp4') {
          if (el && videoType && video) {
            if (video.getAttribute('lazy')) {
              video.setAttribute('lazy', 'true');
            } else {
              const src = video.getAttribute('data-src');
              if (src) {
                video.setAttribute('src', src);
              }
            }

            const player = videoType === 'youtube' ? new YoutubePlayer(el) : new HTMLPlayer(el);
            el.addEventListener('videoPlayer.ready', () => {
              el.addEventListener('videoPlayer.play', () => {
                if (el.dataset.poster === '1') {
                  if (video.requestFullscreen) {
                    video.requestFullscreen();
                    // @ts-ignore
                  } else if (video.webkitEnterFullscreen) {
                    // @ts-ignore
                    video.webkitEnterFullscreen();
                  }
                }
                player.play();
                el.classList.add('is-playing');
                el.classList.remove('is-paused');
              });
              el.addEventListener('videoPlayer.stop', () => {
                player.stop();
                el.classList.add('is-paused');
                el.classList.remove('is-playing');
              });
              el.addEventListener('videoPlayer.mute', () => {
                player.mute();
                el.classList.add('is-muted');
              });
              el.addEventListener('videoPlayer.unmute', () => {
                player.unmute();
                el.classList.remove('is-muted');
              });

              const playButton = el.querySelector('.bg-media-btn-play');
              if (playButton) {
                playButton.addEventListener('click', () => {
                  if (el.classList.contains('is-playing')) {
                    el.dispatchEvent(player.events.stop);
                  } else {
                    el.dispatchEvent(player.events.play);
                  }
                });
              }
              const soundButton = el.querySelector('.bg-media-btn-sound');
              if (soundButton) {
                soundButton.addEventListener('click', () => {
                  if (el.classList.contains('is-muted')) {
                    el.dispatchEvent(player.events.unmute);
                  } else {
                    el.dispatchEvent(player.events.mute);
                  }
                });
              }


              if (el.dataset.poster !== '1') {

                if (el.dataset.paused === '1') {
                  player.stop();
                  el.classList.add('is-stop');
                  el.classList.remove('is-playing');
                } else {
                  player.play();
                  el.classList.add('is-playing');
                  el.classList.remove('is-paused');
                }
              }
            });

            if (video.getAttribute('src') || video.querySelector('[src]')) {
              el.dispatchEvent(player.events.loadScript);
            } else {
              new MutationObserver(() => {
                el.dispatchEvent(player.events.loadScript);
              }).observe(video, {
                attributes: true,
                subtree: true,
                attributeFilter: ['src'],
              });
            }
          }
        } else {
          const url = el?.dataset.url;
          if (url) {
            const playButton = el?.querySelector('button');
            playButton?.addEventListener('click', () => {
              // @ts-ignore
              window.open(url, '_blank').focus();
            });
          }
        }
      });


      const withMap = document.querySelector('.ce-map');
      if (withMap) {
        document.body.classList.add('body--with-map');
      }
    });

    return {
      header,
      lazyLoad,
      photoSwipe,
    };
  },
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const app = createApp(projectRootComponent);
app.use(VueModal);
app.use(DsPhotoSwipe);
app.mount('#page');
